<template>
  <div class="list-container">
    <loader :loading="loading" :backdrop="true"/>
     <ListFilter
      ref="filter"
      :class="{'mb-8': hasFilters || search}"
      :filter="filter"
      :hasFilters="hasFilters"
      :search="search"
      :search-placeholder="searchPlaceholder"
      :initial-values="initialFilter"
      :apply-filter="values => applyFilter(filterMapper(values))"
      :apply-search="applySearch"
    >
      <template v-slot:filters="props">
        <slot name="filters" :reset="props.reset" :filter="filter" />
      </template>
      <template v-slot:quickfilter>
        <slot name="quickfilter" :applyFilter="applyFilter"></slot>
      </template>
      <template v-slot:loader>
        <loader :loading="loading" :backdrop="true"/>
      </template>
    </ListFilter>

    <vuetable v-if="clickable"
      class="list"
      ref="vuetable"
      :api-mode="false"
      :fields="fields"
      :data-manager="dataManager"
      :css="css.table"
      :track-by="trackBy"
      :sort-order="this.isSelected ? null : sortOrder"
      pagination-path="pagination"
      @vuetable:pagination-data="onPaginationData"
      :rowClass="rowClass"
      @vuetable:row-clicked="returnRowData"
    >
      <template v-slot:current_status="props">
        <div @click="handleDelete(props)" v-if="hasIcons">
          <slot name="current_status" :current_status="props.rowData.current_status"/>
        </div>
      </template>
    </vuetable>
     <vuetable v-else
      ref="vuetable"
      :api-mode="false"
      :fields="fields"
      :data-manager="dataManager"
      :css="css.table"
      :track-by="trackBy"
      :sort-order="this.isSelected ? null : sortOrder"
      pagination-path="pagination"
      @vuetable:pagination-data="onPaginationData"
    />
    <vuetable-pagination
      ref="pagination"
      :css="css.pagination"
      @vuetable-pagination:change-page="onChangePage"
    />
  </div>
</template>

<script>
import {Vuetable, VuetablePagination} from 'vue3-vuetable';
import {throttle} from 'lodash-es';
import Loader from '@/components/ui/Loader';
import NotifyMixin from "@/mixins/NotifyMixin";
import AuthMixin from '@/components/auth/AuthMixin';
import ListFilter from "@/components/auth/list/ListFilter";

const css = {
  table: {
    tableClass: 'table-auto w-full',
    tableBodyClass: '',
    tableHeaderClass: 'px-4 py-2',
    tableWrapper: '',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  },
  pagination: {
    wrapperClass: 'flex justify-center py-4',
    activeClass: 'active',
    disabledClass: '',
    pageClass: 'btn-paging',
    linkClass: 'btn-paging',
    paginationClass: '',
    paginationInfoClass: 'pagination-info',
    dropdownClass: '',
    icons: {
      first: '',
      prev: '',
      next: '',
      last: '',
    }
  },
};
export default {
  name: 'List',
  data: function () {
    return {
      query: '',
      loading: false,
      selectedIndex: null,
      isDisabled: false ,
      data: [{ "intent_category_name": "all", "description": "all" }],
      filter: {},
    };
  },
  computed: {
    hasIcons() {
      return !!this.$slots.current_status;
    },
    hasFilters() {
      return !!this.$slots.filters;
    },
  },
  props: {
    css: {
      type: Object,
      default() {
        return css;
      },
    },
    fields: {
      type: Array,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    sortOrder: {
      type: Array
    },
    trackBy: {
      type: String,
      default: 'id'
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    requestParams: {
      type: Object,
      default() {
        return {};
      }
    },
    search: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: 'Start typing to search'
    },
    clickable: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isCommunityRequired: {
      type: Boolean,
      default: false
    },
    initialFilter: {
      type: Object
    },
    filterMapper: {
      type: Function,
      default: function (values) {
        return values;
      }
    },
  },
  components: {
    Loader,
    Vuetable,
    VuetablePagination,
    ListFilter
    // Multiselect,
    // Icon
  },
  mixins: [NotifyMixin, AuthMixin],
  emits: ['deleteRow', 'rowData'],
  watch: {
    '$route': function () {
      if (this.$route.path === `/${this.basePath}`) {
        this.$refs.vuetable.reload();
      }
    },
    query() {
      this.doSearch();
    },
    filter() {
      this.doSearch();
    },
    community: function () {
      if (this.community?.id && this.$props.isCommunityRequired) {
        this.dataManager();
        this.$refs.vuetable.refresh();
      } else if (!this.$props.isCommunityRequired) {
        this.dataManager();
        this.$refs.vuetable.refresh();
      } else {
        this.$refs.vuetable.resetData();
        this.$refs.vuetable.refresh();
      }
    }
  },
  methods: {
    handleDelete(payload) {
      if(this.isSelected) {
        return;
      }
      const { rowIndex, rowData } = payload;
       const paginationData = {
        data: rowData,
        index: rowIndex
      }
      this.selectedIndex = rowIndex;
      this.$emit('deleteRow', paginationData)
    },
    doSearch: throttle(function () {
      if (this.query.length > 2 || this.query.length === 0) {
        this.reload();
      }
    }, 500, {trailing: true}),
    reload() {
      this.$refs.vuetable.resetData();
      this.$refs.vuetable.refresh();
    },
    onSearch({target: {value}}) {
      this.query = value;
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      if(this.isSelected) {
        return;
      }
      this.$refs.vuetable.changePage(page);
    },
    async dataManager(sortOrder = [], pagination = {current_page: 1}) {
      this.loading = true;

      if( this.resource == 'getAllRenewals'){
        const sort = sortOrder[0] ? `name=${sortOrder[0].sortField}&order=${sortOrder[0].direction}` : '';

      const newPagination = this.$refs.vuetable?.makePagination(1, this.pageSize, pagination.current_page);

      const data = [
        {
          unit:100,
          move_in_date:"01/01/2022",
          lease_end_date:"01/02/2022",
          residents:[
            {
              name: 'test name 1',
            },
            {
              name: 'test name 2',
            }
          ],
          agent:'test agent 1',
          status:'not started',
          comments:'test comment',

        },
        {
          unit:200,
          move_in_date:"01/01/2022",
          lease_end_date:"01/02/2022",
          residents:[
            {
              name: 'test name 1',
            },
            {
              name: 'test name 2',
            },
            {
              name: 'test name 3',
            },
            {
              name: 'test name 4',
            }
          ],
          agent:'test agent 2',
          status:'not started',
          comments:'test comment...',

        },
      ]
      this.loading = false;
      return   {
          pagination: newPagination,
          data,
          sort
        };
        
      } else if(this.resource == 'getAllUnits'){
        const sort = sortOrder[0] ? `name=${sortOrder[0].sortField}&order=${sortOrder[0].direction}` : '';

      const newPagination = this.$refs.vuetable?.makePagination(1, this.pageSize, pagination.current_page);

      const data = [
        {
          unit:100,
          statusName:'active',
          lease_exp:'12/13/2021',
          move_in:'12/14/2020',
          move_out:'12/30/2020',
          balance:'$820.00',
          resident:[
            {
              name: 'test name 1',
            },
            {
              name: 'test name 2',
            }
          ],
        },
        {
          unit:101,
          statusName:'on notice',
          lease_exp:'12/13/2021',
          move_in:'12/14/2020',
          move_out:'',
          balance:'$820.00',
          resident:[
            {
              name: 'test name 1',
            },
            {
              name: 'test name 2',
            },
            {
              name: 'test name 3',
            },
            {
              name: 'test name 4',
            }
          ],
        },

      ]
      this.loading = false;

      return   {
          pagination: newPagination,
          data,
          sort
        };

      }
      else if(this.resource == 'getAllResidents'){
        const sort = sortOrder[0] ? `name=${sortOrder[0].sortField}&order=${sortOrder[0].direction}` : '';

      const newPagination = this.$refs.vuetable?.makePagination(1, this.pageSize, pagination.current_page);

      const data = [
        {
          resident:'Dredi Crisall',
          unit: 212,
          statusName:'active',
          lease_exp:'12/13/2021',
          move_in:'12/14/2020',
          move_out:'12/30/2020',
          balance:'$820.00',
        },
        {
          resident:'Aggy Oxbie',
          unit: 104,
          statusName:'future',
          lease_exp:'12/13/2021',
          move_in:'12/14/2020',
          move_out:'12/30/2020',
          balance:'$820.00',
        },
      ]
      this.loading = false;
      return   {
          pagination: newPagination,
          data,
          sort
        };

      }
      else{
        const sort = sortOrder[0] ? 
        {
          sort_by: sortOrder[0].sortField,
          sort_order: sortOrder[0].direction.toUpperCase(),
        }
        : 
        {
          sort_by: 'start_date',
          sort_order: 'DESC',
        };
        const {pageSize: size, query} = this;
        let newPagination = 1
        let data = []

        await this.$leasingDataProvider.getList('getPendingMoveIns', { 
          page: pagination.current_page,
          page_limit: size,
          search: query,
          ...this.requestParams,
          ...sort,
        })   
        .then(response => {
          newPagination = this.$refs.vuetable?.makePagination(response.data.count, this.pageSize, pagination.current_page);
          data= response.data.data;
        }).catch(() => {
          this.notifyError('There was an error getting the occupancy ');
        }).finally(() => {
          this.loading = false;
        });
        
        return   {
          pagination: newPagination,
          data,
          sort
        };
      }
    },
    handleScrollContainer(e) {
      if (this.$refs.vuetable.tablePagination && e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 2) {
        this.onChangePage('next');
      }
    },
    returnRowData(paginationData){
      if(this.isSelected) {
        return;
      }
      this.selectedIndex = paginationData.index;
      this.$emit('rowData', paginationData)
    },
    rowClass(_, index) {
      if(!this.isSelected) {
        return ''
      }
      return this.selectedIndex === index ? 'row--active' : 'row--disabled';
    },
    applyFilter(values) {
      this.filter = values;
      this.$refs.vuetable.changePage(1);
      this.$refs.vuetable.resetData();
    },
    applySearch(value) {
      this.query = value;
    },
  },
  mounted() {
    if (!this.community)
      this.notifyError('please select a community to continue, then refresh the browser');
    this.$refs.vuetable.changePage(1);
  },
};
</script>
<style scoped>
.list-container {
  position: relative;
}
.list ::v-deep(.row--active > td) {
  @apply bg-highlight-100 text-highlight-500;
}
.list ::v-deep(.row--disabled > td) {
  @apply bg-gray-50 text-gray-400;
}
</style>
